import { useState, useEffect, useRef } from 'react'

const useComponentVisible = (initialIsVisible: boolean) => {
  const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible)
  const ref = useRef(null)
  const triggerRef = useRef(null)

  const handleClickOutside = (event: any) => {
    if (
      triggerRef.current &&
      (triggerRef.current as any).contains(event.target)
    ) {
      setIsComponentVisible(!isComponentVisible)
    } else if (ref.current && !(ref.current as any).contains(event.target)) {
      setIsComponentVisible(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  })

  return { ref, triggerRef, isComponentVisible, setIsComponentVisible }
}

export default useComponentVisible
