import { css } from 'styled-components'

export const containerStyles = css`
  display: flex;
  flex-direction: column;
  :hover {
    cursor: default;
  }
`

export const messageContentContainerStyles = css`
  display: flex;
  flex-direction: row;
  padding: 8px 24px;
`

export const messageContainerStyles = css`
  display: flex;
  overflow: hidden;
  flex-direction: column;
  color: white;
  padding-left: 10px;
`

export const messageBodyStyles = css`
  font-size: 13px;
  line-height: 15px;
  padding: 4px 0px;
  ${({ theme }) => theme.fonts.normal};
  color: ${({ theme }) => theme.colors.primaryText};
`
