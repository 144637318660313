// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type { IStreamMessage } from '@mandolin-dev/ts-sdk'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type { ChatError } from 'src/hooks/useGeneralChat'

/**
 * Orders by created_at and combines message events for display
 * @returns IStreamMessage[]
 */
export const sanitizeMessages = (
  messages: IStreamMessage[]
): IStreamMessage[] => {
  let sanitizedMessages = new Map()
  messages
    .sort(
      (a, b) =>
        new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
    )
    .forEach((message) => {
      sanitizedMessages.set(message.message.id, message)
    })
  return Array.from(sanitizedMessages.values())
}

export const shouldShowBadge = (message: IStreamMessage) =>
  ['moderator', 'admin'].includes(message.message?.user?.role) ||
  message.message?.isModerator

export const checkForKnownErrors = (error: any) => {
  let chatError: ChatError = {
    title: 'Error',
    message: 'An unknown error occurred.',
    code: -1
  }
  console.log(`Chat Error: ${JSON.stringify(error)}`)
  switch (error.code) {
    case 60: //slow mode
      chatError.code = error.code
      chatError.title = 'Chat Slow Mode'
      chatError.message =
        'When chat is busy we temporarily limit how often you can post. Please try again in a few moments.'
      break
    case 17: //suspended/banned
      chatError.code = error.code
      chatError.title = 'User Banned'
      chatError.message = 'Your account is currently suspended from chat.'
      break
    case 503: //chat overload max fans 5k
      chatError.code = error.code
      chatError.title = `It's a party!`
      chatError.message = `We are experiencing unusually high chat volumes, please try again in a few moments.`
      break
    case 701: // profanity filter
      chatError.code = error.code
      chatError.title = error.title
      chatError.message = error.message
      break
    case 702: // stream issues filter
      chatError.code = error.code
      chatError.title = error.title
      chatError.message = error.message
      break
    case 280: // max character count
      chatError.code = error.code
      chatError.title = error.title
      chatError.message = error.message
      break
  }

  return chatError
}

/* scales the fonts and things for large chat mode (studio) */
export const scale = (scale: number | undefined, base: number | string) => {
  if (scale === undefined) return base

  // if a string like 15px
  if (typeof base === 'string') {
    const numBase = Number(base.split('px')[0])
    const newSize = numBase * scale
    return `${newSize.toString()}px`
  } else {
    return scale * base
  }
}
