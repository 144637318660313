import { css } from 'styled-components'
import { readableColor } from 'polished'

export const container = css`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 1;
`
export const btn = css`
  background-color: ${({ theme }) => theme.colors.buttonBackground};
  background-color: #fff;
  margin: 0;
  display: flex;
  height: 21px;
  border-radius: 24px;
  overflow: hidden;
  padding: 0px 8px;
  align-items: center;
`

export const text = css`
  color: ${({ theme }) => readableColor(theme.colors.buttonBackground)};
  ${({ theme }) => theme.fonts.medium};
  font-size: 12px;
  line-height: 14px;
`
