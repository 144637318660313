import { css } from 'styled-components'
import { scale } from '../../services/utils'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type { ChatTheme } from '../../theme/ChatTheme'

export const containerStyles = css`
  display: flex;
  padding: 0 16px;
  flex-direction: column;
`
export const messageDeletedStyles = css`
  font-size: ${({ theme }: { theme: ChatTheme }) =>
    scale(theme.scale?.fonts, '12px')};
  color: ${({ theme }) => theme.colors.primaryText};
  padding: 8px;
  background-color: ${({ theme }) => theme.colors.inputBorder};
  border-radius: 8px;
  overflow: hidden;
`

export const hoverContainerStyles = css`
  border-radius: 4px;
  :hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.highlight};
  }
`

export const messageContentContainerStyles = css`
  display: flex;
  flex-direction: row;
  padding: 8px;
`

export const messageContainerStyles = css`
  font-size: ${({ theme }: { theme: ChatTheme }) =>
    scale(theme.scale?.fonts, '13px')};
  line-height: ${({ theme }: { theme: ChatTheme }) =>
    scale(theme.scale?.fonts, '15px')};
  display: flex;
  overflow: hidden;
  flex-direction: column;
  color: white;
  padding-left: 10px;
`

export const messageBodyStyles = css`
  padding: 4px 0px;
  ${({ theme }) => theme.fonts.regular};
  color: ${({ theme }) => theme.colors.primaryText};
`

export const messageAuthorStyles = css`
  ${({ theme }) => theme.fonts.bold};
  color: ${({ theme }) => theme.colors.primaryHeaderText};
`

export const avatarStyles = css`
  height: ${({ theme }: { theme: ChatTheme }) =>
    scale(theme.scale?.images, '32px')};
  width: ${({ theme }: { theme: ChatTheme }) =>
    scale(theme.scale?.images, '32px')};
  border-radius: ${({ theme }: { theme: ChatTheme }) =>
    scale(theme.scale?.images, '16px')};
  aspect-ratio: 1;
`

export const actionsContainerStyles = css`
  display: flex;
  flex-direction: row;
  padding: 8px;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.highlight};
`
const buttonStyles = css`
  font-size: ${({ theme }: { theme: ChatTheme }) =>
    scale(theme.scale?.fonts, '12px')};
  border-radius: 24px;
  border: none;
  padding: 4px 6px;
  cursor: pointer;
  ${({ theme }) => theme.fonts.medium};
`

export const banUserButtonStyles = css`
  background-color: ${({ theme }) => theme.colors.error};
  ${buttonStyles}
`

export const deleteMessageButtonStyles = css`
  background-color: ${({ theme }) => theme.colors.buttonBackground};
  ${buttonStyles}
`
