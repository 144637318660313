// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type { DefaultApi, IStreamMessage } from '@mandolin-dev/ts-sdk'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type { WsActions } from '@mandolin-dev/websocket-sdk'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { sanitizeMessages } from '../services/utils'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { StreamChat, Channel } from 'stream-chat'

const MAX_CHAR_COUNT = 280
export interface UseGeneralChatProps {
  eventId: string
  user: {
    id: string
    name: string
    avatar?: string
    userIsManager?: boolean
  }
  restClient: DefaultApi
  wsActions: WsActions
  invertMessages?: boolean
  eventCallback?: (event: string) => void
}

export type ChatError = {
  code: number
  message: string
  title?: string
}

export interface UseGeneralChatState {
  /**
   * flag that indicates when the read-only websocket and stream are initialized
   */
  loading: boolean
  /**
   * messages is a combination of our read-only messages and channel.state.messages
   */
  messages: IStreamMessage[]
  /**
   * flag that determines if chat is connecting
   */
  chatIsConnecting: boolean
  /**
   * flag that determines if chat is in slow mode
   */
  slowMode: boolean
  /**
   * Is set on every message received (Useful to trigger an update in the UI)
   */
  lastEventReceived: Date
  /**
   * flag that determines if we couldn't conntect to chat
   */
  errorConnecting: boolean
}

export interface UseGeneralChat extends UseGeneralChatState {
  sendMessage: (text: string) => Promise<any>
  /**
   * bans a user from the event chat
   */
  banUser: (uid: string) => Promise<any>
  /**
   * removes a message from the event chat
   */
  deleteMessage: (message: IStreamMessage) => Promise<any>
  /**
   * pins a message in event chat If the passed message is already pinned it will be un-pinned
   */
  pinMessage: (message: IStreamMessage) => Promise<any>
}

const defaultState = {
  loading: true,
  messages: [],
  chatIsConnecting: false,
  slowMode: false,
  lastEventReceived: new Date(),
  errorConnecting: false
}

const useGeneralChat = ({
  eventId,
  user,
  restClient,
  wsActions,
  invertMessages = false,
  eventCallback
}: UseGeneralChatProps): UseGeneralChat => {
  const [state, setState] = useState<UseGeneralChatState>(defaultState)
  const timer = useRef<ReturnType<typeof setTimeout>>()
  // Flag that indicates if we've initialized our connection to our chat service
  const chatInitialized = useRef<boolean>(false)
  // Flag that indicates if user is connected to the stream service users are discconected after inactivity as a cost optimization
  const chatClientConnected = useRef<boolean>(false)
  // String generated by the stream sdk to connect to a channel
  const chatToken = useRef<string>()
  // Instance of StreamChat passed to the Chat component
  const chatClient = useRef<StreamChat>()
  // Instance of Channel passed to the Channel component
  const channel = useRef<Channel>()
  // Array of stream listeners
  const listeners = useRef<
    (
      | {
          unsubscribe: () => void
        }
      | undefined
    )[]
  >([])
  // tracks the hooks initialization
  const useGeneralChatInitalizted = useRef<boolean>(false)

  const handledChannelUpdated = useCallback((ch) => {
    if (ch.channel.cooldown) {
      setState((prev) => ({
        ...prev,
        slowMode: true
      }))
    } else {
      setState((prev) => ({
        ...prev,
        slowMode: false
      }))
    }
  }, [])

  const handledMessageReceived = useCallback(
    (message) => {
      setState((prev) => {
        let stateToReturn = { ...prev, lastEventReceived: new Date() }

        let indexToUpdate
        if (invertMessages) {
          indexToUpdate = [...prev.messages].findIndex(
            (msg) => msg.message?.id === message.message?.id
          )
        } else {
          // To improve the performance of findIndex we reverse the array so fewer items are iterated over
          indexToUpdate = [...prev.messages]
            .reverse()
            .findIndex((msg) => msg.message?.id === message.message?.id)
        }

        switch (message.type) {
          case 'message.new': {
            if (indexToUpdate === -1) {
              stateToReturn.messages = invertMessages
                ? [message, ...prev.messages]
                : [...prev.messages, message]
            }
            break
          }
          case 'message.updated': {
            if (indexToUpdate !== -1) {
              // After we find the index of the updated item we need to subtract indexToUpdate from the length of messages (minus 1 since .length doesn't start at zero)
              stateToReturn.messages[
                invertMessages
                  ? indexToUpdate
                  : prev.messages.length - 1 - indexToUpdate
              ] = message
            } else {
              // A message was updated that was not initially fetched, We add it to the stack
              stateToReturn.messages = invertMessages
                ? [...prev.messages, message]
                : [message, ...prev.messages]
            }
            break
          }
          case 'message.deleted': {
            if (indexToUpdate !== -1) {
              // After we find the index of the deleted item we need to subtract indexToUpdate from the length of messages (minus 1 since .length doesn't start at zero)
              stateToReturn.messages[
                invertMessages
                  ? indexToUpdate
                  : prev.messages.length - 1 - indexToUpdate
              ] = message
            }
            break
          }
          default:
            break
        }
        return stateToReturn
      })
    },
    [invertMessages]
  )

  const transitionToWriteSocket = useCallback(() => {
    // tear down any existing sockets
    listeners.current.forEach((listener) => listener?.unsubscribe())
    // connect to the writer socket
    channel.current?.on('channel.updated', handledChannelUpdated)
    channel.current?.on('message.new', handledMessageReceived)
    channel.current?.on('message.updated', handledMessageReceived)
    channel.current?.on('message.deleted', handledMessageReceived)
    console.log(
      'transitionToWriteSocket: transitioned from read-only to stream'
    )
    // listeners.current.push(listenerNew, listenerDeleted, listenerChannelUpdated)
  }, [handledChannelUpdated, handledMessageReceived])

  const connectToReadOnlySocket = useCallback(() => {
    const subscribeToChannel = wsActions.client.makeSubscriber('streamChat')
    const listener = subscribeToChannel(eventId, handledMessageReceived)
    listeners.current.push({
      unsubscribe: listener
    })
    console.log('connectToReadOnlySocket: listener has been initialized')
  }, [eventId, handledMessageReceived, wsActions])

  const transitionToReadyOnlySocket = useCallback(async () => {
    // tear down any existing sockets
    listeners.current.forEach((listener) => listener?.unsubscribe())
    // call additional cleanup methods for the writer socket
    // await channel.current?.stopWatching()
    chatClient.current?.disconnectUser()
    // connect to the read only socket
    connectToReadOnlySocket()
    chatClientConnected.current = false
    console.log(
      'transitionToReadyOnlySocket: transitioned from stream to read-only'
    )
  }, [connectToReadOnlySocket])

  const connectToChannel = useCallback(async () => {
    const newChannel = chatClient.current?.channel('livestream', eventId)
    channel.current = newChannel
    await newChannel?.watch()
    transitionToWriteSocket()
    console.log('connectToChannel: channel connected')
  }, [eventId, transitionToWriteSocket])

  const connectToChat = useCallback(async () => {
    try {
      await chatClient.current?.connectUser(
        {
          id: user.id
        },
        chatToken.current
      )
      console.log('connectToChat: user connected')
    } catch (error) {
      console.log('connectToChat error', error)
      throw error
    }
  }, [user.id])

  const fetchTokenAndChannel = useCallback(async () => {
    try {
      const response = await restClient.getChatToken()
      chatClient.current = StreamChat.getInstance(response.data.apiKeyId)
      chatToken.current = response.data.token
      const joinResponse = await restClient.joinChannel({
        joinChannelRequestDTO: {
          channelId: eventId,
          userName: user.name || '',
          userPhoto:
            user.avatar || `https://mandolin.com/images/avatar-default.svg`,
          userIsManager: user.userIsManager || false
        }
      })
      console.log(
        'fetchTokenAndChannel',
        'chat client setup and tokens fetched'
      )
      if (joinResponse.data.cooldown) {
        setState((prev) => ({
          ...prev,
          slowMode: true
        }))
      }
    } catch (error: any) {
      console.log('fetchTokenAndChannel error', error)
      throw error
    }
  }, [eventId, restClient, user.avatar, user.name, user.userIsManager])

  const initializeChat = useCallback(async () => {
    try {
      await fetchTokenAndChannel()
      await connectToChat()
      await connectToChannel()
      console.log('initializeChat')
    } catch (error) {
      console.log('initializeChat error', error)
      throw error
    }
  }, [fetchTokenAndChannel, connectToChat, connectToChannel])

  // The hook that kicks it all off
  useEffect(() => {
    const initChat = async () => {
      console.log('init chat')
      try {
        const recentMessagesResponse = await restClient.getStreamChatMessages({
          channelId: eventId,
          limit: 50
        })
        const recentPinnedMessagesResponse =
          await restClient.getLatestPinnedStreamChatMessages({
            channelId: eventId
          })
        if (user.userIsManager) {
          await initializeChat()
          chatInitialized.current = true
          chatClientConnected.current = true
        } else {
          connectToReadOnlySocket()
        }
        const messages = sanitizeMessages([
          ...recentPinnedMessagesResponse.data.map((message) => ({
            message,
            ...message
          })),
          ...recentMessagesResponse.data
        ]).filter((m) => m.message.type !== 'deleted')
        setState((prev) => ({
          ...prev,
          loading: false,
          messages: invertMessages ? messages.reverse() : messages
        }))
      } catch (error: any) {
        console.log('initHook error', error)
        setState((prev) => ({ ...prev, errorConnecting: true, loading: false }))
      }
    }
    if (eventId && restClient && !useGeneralChatInitalizted.current) {
      useGeneralChatInitalizted.current = true
      initChat()
    }
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      listeners.current?.forEach((listener) => listener?.unsubscribe())
      chatClient.current?.disconnectUser()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const clearError = () => setState((prev) => ({ ...prev, error: undefined }))

  const executeStreamAction = useCallback(
    async (action: () => Promise<any>, actionLabel: string) => {
      // Local instance to track if we're still connecting to the chat service
      // This prevents users who spam from triggering multiple connections
      let chatIsConnecting = state.chatIsConnecting
      if (chatIsConnecting) {
        throw {
          message: 'We are still connecting to chat',
          code: 0
        } as ChatError
      }
      console.log(`starting action: ${actionLabel}`)
      // invalidate disconnect timer
      // Every time a user sends a message we persist their connection by invalidating the current disconnect timer
      timer.current && clearTimeout(timer.current)
      try {
        // check if chatInitialized
        if (!chatInitialized.current) {
          // we update chatIsConnecting so consumers can react appropriately
          setState((prev) => ({ ...prev, chatIsConnecting: true }))
          chatIsConnecting = true
          // connect to stream channel
          await initializeChat()
          chatInitialized.current = true
          chatClientConnected.current = true
        } else if (!chatClientConnected.current) {
          // we update chatIsConnecting so consumers can react appropriately
          setState((prev) => ({ ...prev, chatIsConnecting: true }))
          chatIsConnecting = true
          // connect to stream and channel
          await connectToChat()
          await connectToChannel()
          chatClientConnected.current = true
        }
        // execute stream action
        await action()

        if (chatIsConnecting) {
          // set chatIsConnecting to false so consumers can react appropriately
          setState((prev) => ({ ...prev, chatIsConnecting: false }))
        }
      } catch (error: any) {
        throw error
      }
      if (!user.userIsManager) {
        // start new disconnect timer
        timer.current = setTimeout(transitionToReadyOnlySocket, 10000)
      }
    },
    [
      connectToChannel,
      connectToChat,
      initializeChat,
      state.chatIsConnecting,
      transitionToReadyOnlySocket,
      user.userIsManager
    ]
  )

  const sendMessage = useCallback(
    (message: string) => {
      if (message.length > MAX_CHAR_COUNT) {
        throw {
          code: 280,
          title: 'Too many characters',
          message: `You cannnot send a message longer than ${MAX_CHAR_COUNT} characters.`
        }
      }
      if (eventCallback !== undefined) eventCallback('messageSent')
      return executeStreamAction(
        async () =>
          channel.current?.sendMessage(
            {
              text: message,
              isModerator: user.userIsManager
            },
            { skip_push: true }
          ),
        'sendMessage'
      )
    },
    [eventCallback, executeStreamAction, user.userIsManager]
  )

  const banUser = useCallback(
    async (uid: string) => {
      return executeStreamAction(async () => {
        try {
          if (user.userIsManager) {
            await channel.current?.banUser(uid, {})
            return {
              code: 'success',
              message: 'User was removed from chat.'
            }
          } else {
            throw 'You do not have access to remove users from this chat.'
          }
        } catch (error: any) {
          throw error
        }
      }, 'banUser')
    },
    [executeStreamAction, user.userIsManager]
  )

  const pinMessage = useCallback(
    async (message) =>
      executeStreamAction(async () => {
        try {
          if (
            (user.userIsManager || message?.message?.user?.id === user.id) &&
            message.message
          ) {
            console.log(chatClient.current, message.message.id)
            if (message.message?.pinned) {
              console.log('unpin')
              await chatClient.current?.unpinMessage(message.message.id)
            } else {
              console.log('pin')
              await chatClient.current?.pinMessage(message.message.id)
            }
            return {
              code: 'success',
              message: `Message was ${
                message.message?.pinned ? 'un-pinned' : 'pinned'
              } from chat.`
            }
          } else {
            throw 'You do not have access to remove messages from this chat.'
          }
        } catch (error: any) {
          console.log('pinMessage error', error)
          throw error
        }
      }, 'pinMessage'),
    [executeStreamAction, user.id, user.userIsManager]
  )

  const deleteMessage = useCallback(
    async (message: IStreamMessage) =>
      executeStreamAction(async () => {
        try {
          if (user.userIsManager || message.message.user.id === user.id) {
            await chatClient.current?.deleteMessage(message.message.id)
            return {
              code: 'success',
              message: 'Message was removed from chat.'
            }
          } else {
            throw 'You do not have access to remove messages from this chat.'
          }
        } catch (error: any) {
          console.log('deleteMessage error', error)
          throw error
        }
      }, 'deleteMessage'),
    [executeStreamAction, user.id, user.userIsManager]
  )

  return useMemo(
    () => ({
      ...state,
      sendMessage,
      clearError,
      banUser,
      deleteMessage,
      pinMessage
    }),
    [state, sendMessage, banUser, deleteMessage, pinMessage]
  )
}

export default useGeneralChat
