import { css, keyframes } from 'styled-components'

const skeleton = keyframes`
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: -135% 0%;
  }
`

export const messageContentStyles = css`
  position: relative;
  width: 100%;
  padding: 4px;
`

export const messageStyles = css`
  display: flex;
  align-items: stretch;
  padding-left: 1rem;
  @media (min-width: 1024px) {
    padding: 8px;
  }
`

export const skeletonAvatarStyles = css`
  flex: 0 0 38px;
  width: 38px;
  height: 38px;
  border-radius: 22px;
  background: linear-gradient(
    90deg,
    rgba(48, 48, 48, 0.2),
    rgba(48, 48, 48, 0.4),
    rgba(48, 48, 48, 0.2)
  );
  background-size: 400% 100%;
  animation: ${skeleton} 1.3s ease infinite;
`

export const skeletonUsernameStyles = css`
  display: block;
  height: 14px;
  background: linear-gradient(
    90deg,
    rgba(48, 48, 48, 0.2),
    rgba(48, 48, 48, 0.4),
    rgba(48, 48, 48, 0.2)
  );
  background-size: 400% 100%;
  animation: ${skeleton} 1.3s ease infinite;
`

export const skeletonTextStyles = css`
  width: 100%;
  height: 14px;
  background: linear-gradient(
    90deg,
    rgba(48, 48, 48, 0.2),
    rgba(48, 48, 48, 0.4),
    rgba(48, 48, 48, 0.2)
  );
  background-size: 400% 100%;
  animation: ${skeleton} 1.3s ease infinite;
`
export const spacerStyles = css`
  height: 4px;
  width: 100%;
`
