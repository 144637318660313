// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type { ChatTheme } from 'src/theme/ChatTheme'
import { css } from 'styled-components'

export const containerStyles = css`
  position: absolute;
  top: 8px;
  left: 8px;
  right: 8px;
  padding: 8px 0;
  background-color: ${({ theme }: { theme: ChatTheme }) =>
    theme.colors.headerBackground};
  border-radius: 16px;
  z-index: 10;
`
