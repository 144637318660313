// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type { IStreamMessage } from '@mandolin-dev/ts-sdk'
import React from 'react'

const useBottomTracking = ({ messages }: { messages: IStreamMessage[] }) => {
  const [isBottom, setIsBottom] = React.useState<boolean>(true)
  const lastMessage = React.useRef<number>()

  React.useEffect(() => {
    if (messages.length && isBottom) {
      lastMessage.current = messages.length
    }
  }, [messages, isBottom])

  const bottomString = () => {
    const newMessagesSinceLastDetachment =
      messages.length - (lastMessage.current || messages.length)
    return newMessagesSinceLastDetachment > 0
      ? `${newMessagesSinceLastDetachment}+ new message${
          newMessagesSinceLastDetachment > 1 ? 's' : ''
        }`
      : 'new messages'
  }

  const setLastMessage = (newVal: number) => {
    lastMessage.current = newVal
  }

  return {
    isBottom,
    setIsBottom,
    lastMessage: lastMessage.current,
    setLastMessage,
    bottomString: bottomString()
  }
}

export default useBottomTracking
