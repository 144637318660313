// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type { IStreamMessage } from '@mandolin-dev/ts-sdk'
import { atom } from 'jotai'

export const scrollToBottomAtom = atom<
  { scrollToBottom: () => void } | undefined
>(undefined)
export const selectedMessageAtom = atom<
  | {
      message: IStreamMessage
      top: number
    }
  | undefined
>(undefined)
