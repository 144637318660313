import React from 'react'
import { Picker } from 'emoji-mart'
import 'emoji-mart/css/emoji-mart.css'
import styled from 'styled-components'
import { Smile } from '@styled-icons/boxicons-regular'
import useComponentVisible from '../../hooks/useComponentVisible'

interface EmojiPickerProps {
  setInputValue: (value: any) => void
}

const EmojiIcon = styled(Smile)`
  position: absolute;
  right: 50px;
  width: 22px;
  top: 0;
  bottom: 0;
  padding-top: 6px;
  cursor: pointer;
  fill: ${({ theme }) => theme.colors.icons};
  @media (max-width: 1111px) {
    display: none;
  }
`

const EmojiContainer = styled.div`
  span {
    .emoji-mart {
      bottom: 40px;
      display: block;
      position: absolute;
      right: 0px;
      width: 338px;
      z-index: 2;
    }
  }
`

const EmojiPicker: React.FC<EmojiPickerProps> = ({ setInputValue }) => {
  const { ref, triggerRef, isComponentVisible } = useComponentVisible(false)

  const onSelect = (emoji: any) => {
    // analytics.logEvent('emoji_selected', {
    //   message: emoji.name
    // })
    const inputElement = document.getElementById('messageInput') as any
    setInputValue(
      inputElement?.value?.length > 0
        ? `${inputElement?.value} ${emoji.native}`
        : emoji.native
    )
    inputElement?.focus()
    // setIsComponentVisible(false)
  }

  return (
    <EmojiContainer>
      <EmojiIcon ref={triggerRef} aria-label="emojis" />
      {isComponentVisible && (
        <span ref={ref} aria-label="emoji picker">
          <Picker
            emoji="point_up"
            onSelect={onSelect}
            recent={[
              'star-struck',
              'clap',
              'fist',
              'the_horns',
              'heart',
              'raised_hands'
            ]}
            set="apple"
            theme="dark"
            title="Show some love..."
          />
        </span>
      )}
    </EmojiContainer>
  )
}

export default EmojiPicker
