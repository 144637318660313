import React, { useState } from 'react'
import styled from 'styled-components'

import { avatarStyles } from './Message.styles'

const Avatar = styled.img`
  ${avatarStyles}
  background-color: rgba(0,0,0,0.25);
`

const DEFAULT_AVATAR = 'https://mandolin.com/images/avatar-placeholder.png'

const AvatarLoader = ({ url }: { url: string }) => {
  const [src, setSrc] = useState(url)
  return (
    <Avatar
      src={src}
      onError={() => {
        if (src === url) {
          setSrc(DEFAULT_AVATAR)
        }
      }}
    />
  )
}

export default AvatarLoader
