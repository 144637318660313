import { useEffect, useCallback, useRef } from 'react'
import throttle from 'lodash.throttle'

/**
 * useThrottle
 * Throttles a function with a timeout and ensures
 * that the callback function runs at most once in that duration
 *
 * @param fn The callback to throttle
 * @param timeout Throttle timeout
 */
function useThrottle(fn: Function, timeout: number = 300) {
  const options = { leading: true, trailing: false } // add custom lodash options
  const cbRef = useRef(fn)
  // use mutable ref to make useCallback/throttle not depend on `cb` dep
  useEffect(() => {
    cbRef.current = fn
  })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(
    throttle((...args) => cbRef.current(...args), timeout, options),
    [timeout]
  )
}

export default useThrottle
