export interface ChatTheme {
  colors: {
    primaryText: string
    secondaryText: string
    primaryHeaderText: string
    secondaryHeaderText: string
    headerBackground: string
    background: string
    border: string
    highlight: string
    alertBackground: string
    alertBodyText: string
    alertHeaderText: string
    buttonBackground: string
    error: string
    overlay: string
    icons: string
    inputText: string
    inputBorder: string
  }
  fonts: {
    light: string
    regular: string
    normal: string
    medium: string
    demiBold: string
    bold: string
    extraBold: string
    black: string
    extraBlack: string
  }
  scale: {
    fonts: number
    images: number
  }
}

export const DefaultChatTheme = {
  colors: {
    primaryText: '#A4A9AC',
    secondaryText: '#394249',
    primaryHeaderText: '#CFD2D4',
    secondaryHeaderText: '#394249',
    headerBackground: '#0e131b',
    background: '#1E242D',
    border: '#FFFFFF',
    highlight: 'rgba(57, 66, 73, 0.8)',
    alertBodyText: '#000000',
    alertHeaderText: '#000000',
    alertBackground: '#EAE9E6',
    buttonBackground: '#FFFFFF',
    error: '#FF7575',
    overlay: 'rgba(0, 0, 0, 0.8);',
    icons: '#FFFFFF',
    inputText: '#FFFFFF',
    inputBorder: '#394249'
  },
  fonts: {
    light: `
    font-family: "TTNormsPro-Light";
  `,
    regular: `
    font-family: "TTNormsPro-Regular";
  `,
    normal: `
    font-family: "TTNormsPro-Normal";
  `,
    medium: `
    font-family: "TTNormsPro-Medium";
  `,
    demiBold: `
    font-family: "TTNormsPro-DemiBold";
  `,
    bold: `
    font-family: "TTNormsPro-Bold";
  `,
    extraBold: `
    font-family: "TTNormsPro-ExtraBold";
  `,
    black: `
    font-family: "TTNormsPro-Black";
  `,
    extraBlack: `
    font-family: "TTNormsPro-ExtraBlack";
  `
  },
  scale: {
    fonts: 1,
    images: 1
  }
}
